import React from "react";
import { Box } from "@mui/material";
import SpeedDialBtn from "../../../views/SpeedDial/SpeedDialBtn";

const WrapperNotAuthed = ({ component }) => {
  return (
    <>
      <SpeedDialBtn />
      <Box width={"100%"} flexGrow={1}>
        {component}
      </Box>
    </>
  );
};

export default WrapperNotAuthed;
