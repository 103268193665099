import React from "react";
import Footer from "../Footer/Footer";
import { Box } from "@mui/material";
import OrdersNotification from "../../common/OrdersNotification";
import SpeedDialBtn from "../../../views/SpeedDial/SpeedDialBtn";
import PromotionBanner from "../../../views/Wallet/PromotionBanner";
import Navbar from "../Navbar/Navbar";

const Wrapper = ({ component }) => {
  return (
    <>
      <PromotionBanner />
      <OrdersNotification />
      <SpeedDialBtn />
      <Navbar />
      <Box width={"100%"} flexGrow={1}>
        {component}
      </Box>
      <Footer />
    </>
  );
};

export default Wrapper;
