//SECTION - GENERAL
import React from "react";
import Snackbar from "../src/components/common/Snackbar";
import "./App.css";

//SECTION - PROVIDERS
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import Smartlook from "./utils/api/smartlook";

import { initSentry } from "./utils/api/sentry";
import CustomRouter from "./routes/CustomRouter";
import useAppSetup from "./utils/hooks/useAppSetup";

initSentry();

const App = () => {
  const { theme, cache } = useAppSetup();

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <Smartlook />
        <CssBaseline />
        <CustomRouter />
        <Snackbar />
      </ThemeProvider>
    </CacheProvider>
  );
};
export default App;
