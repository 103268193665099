import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSmartlook } from "../api/smartlook";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleAnalytics } from "../api/googleAnalytics";
import {
  isAuthenticated,
  validateWatchtowerToken,
} from "../api/Authentication";
import IsEmpty from "../validation/IsEmpty";
import { jwtDecode } from "jwt-decode";
import { setUserContext } from "../api/sentry";
import { checkBetaUser, updateUserLang } from "../../app/features/auth/auth";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { createTheme } from "@mui/system";
import baseTheme from "../materialUI/Theme";
import { checkClientValidity } from "../../app/features/wallet/wallet";

const useAppSetup = () => {
  //SECTION - general
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  //SECTION - smart look & google analytics
  const { setUserAttributes, alreadySetUserAttributes } = useSmartlook();
  const { logUserProperties, initGA4 } = useGoogleAnalytics();

  //SECTION - useSelector
  const betaUser = useSelector((state) => state.authReducer.betaUser);

  useEffect(() => {
    initGA4();
    if (
      isAuthenticated() &&
      !alreadySetUserAttributes &&
      validateWatchtowerToken() &&
      !IsEmpty(betaUser)
    ) {
      const user = jwtDecode(localStorage.getItem("watchTowerAccessToken"));
      const userLang = localStorage.getItem("lng");
      // update google analytics user properties
      logUserProperties(user.user_id, {
        name: `${user.user.first_name} ${user.user.last_name}`,
        phone: user.user.phone,
        is_staff: user.user.is_staff,
        isBetaUser: betaUser,
        lang: userLang,
      });
      // update smartlook user attributes
      setUserAttributes(user.user_id, {
        name: `${user.user.first_name} ${user.user.last_name}`,
        phone: user.user.phone,
        is_staff: user.user.is_staff,
        language: userLang,
        isBetaUser: betaUser,
      });
      // update sentry user attributes
      setUserContext(user.user_id, {
        phone: user.user.phone,
        is_staff: user.user.is_staff,
        isBetaUser: betaUser,
        lang: userLang,
      });
      // update user language in DB
      if (userLang) {
        dispatch(
          updateUserLang({ lang: localStorage.getItem("lng").toUpperCase() })
        );
      } else {
        dispatch(updateUserLang({ lang: i18n.language.toUpperCase() }));
      }
    }
  }, [
    setUserAttributes,
    betaUser,
    alreadySetUserAttributes,
    dispatch,
    i18n.language,
    initGA4,
    logUserProperties,
  ]);

  useEffect(() => {
    // beta user & client validity check
    if (localStorage.getItem("watchTowerAccessToken")) {
      dispatch(checkBetaUser());
      dispatch(checkClientValidity());
    }
  }, [dispatch]);

  useEffect(() => {
    document.body.dir = i18n.dir();
    document.documentElement.setAttribute("dir", i18n.dir());
    localStorage.setItem("i18nextLng", i18n.dir() === "rtl" ? "ar" : "en");
    localStorage.setItem("lng", i18n.language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const cache = useMemo(() => {
    return createCache({
      key: i18n.language === "ar" ? "muirtl" : "mui",
      stylisPlugins: i18n.language === "ar" ? [prefixer, rtlPlugin] : [],
    });
  }, [i18n.language]);

  const theme = useMemo(() => {
    return createTheme({
      ...baseTheme,
      direction: i18n.language === "ar" ? "rtl" : "ltr",
    });
  }, [i18n.language]);

  return { theme, cache };
};

export default useAppSetup;
