//SECTION - ROUTERS & GENERAL IMPORTS
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";
import AuthProtected from "../routes/AuthProtected";
import LoginProtected from "../routes/LoginProtected";
import { CircularProgress } from "@mui/material";

//SECTION - WRAPPERS
import WrapperNotAuthed from "../components/Template/Wrapper/WrapperNotAuthed";
import Wrapper from "../components/Template/Wrapper/Wrapper";

//SECTION - PAGES
const LoginPage = lazy(() => import("../views/Login/LoginPage"));
const Home = lazy(() => import("../views/Home/Home"));
const Cart = lazy(() => import("../views/Cart/Cart"));
const Payment = lazy(() => import("../views/Payment/Payment"));
const TermsAndConditions = lazy(() =>
  import("../views/TermsAndConditions/TermsAndConditions")
);
const Orders = lazy(() => import("../views/Orders/Orders"));
const Offers = lazy(() => import("../views/Offers/Offers"));
const Wallet = lazy(() => import("../views/Wallet/Wallet"));
const ErrorPage = lazy(() => import("../components/Template/Error/ErrorPage"));
const WalletCreateInvoice = lazy(() =>
  import("../views/Wallet/WalletCreateInvoice")
);
const OrderTracking = lazy(() =>
  import("../views/OrderTracking/OrderTracking")
);
const CreditServiceIndex = lazy(() =>
  import("../views/CreditService/pages/CreditServiceIndex")
);
const BranchesIndex = lazy(() =>
  import("../views/Branches/pages/BranchesIndex")
);
const IndexWallet = lazy(() => import("../views/Wallet/IndexWallet"));

const CustomRouter = () => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        <Routes>
          <Route element={<LoginProtected />}>
            <Route
              path="/login"
              element={<WrapperNotAuthed component={<LoginPage />} />}
            />
          </Route>
          <Route element={<AuthProtected />}>
            {/* //TODO - Remove comment when enabling feature (1/3) */}
            <Route
              path="/branches"
              element={<Wrapper component={<BranchesIndex />} />}
            />
            <Route path="/home" element={<Wrapper component={<Home />} />} />
            <Route path="/cart" element={<Wrapper component={<Cart />} />} />
            <Route
              path="/orders"
              element={<Wrapper component={<Orders />} />}
            />
            <Route
              path="/offers"
              element={<Wrapper component={<Offers />} />}
            />
            <Route
              path="/payment"
              element={<Wrapper component={<Payment />} />}
            />
            <Route
              path="/installment-services"
              element={<Wrapper component={<IndexWallet />} />}
            />
            <Route
              path="/wallet"
              element={<Wrapper component={<Wallet />} />}
            />
            <Route
              path="/wallet/createInvoice/:merchantName?"
              element={<Wrapper component={<WalletCreateInvoice />} />}
            />
            <Route
              path="/credit-service"
              element={<Wrapper component={<CreditServiceIndex />} />}
            />
          </Route>
          <Route path="*" element={<Navigate to="/login" replace />} />
          <Route
            path="/error/:code"
            element={<Wrapper component={<ErrorPage />} />}
          />
          <Route
            path="/order-tracking/:id"
            element={<Wrapper component={<OrderTracking />} />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default CustomRouter;
