import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  towerInstance,
  watchTowerInstance,
} from "../../../utils/api/axiosConfig";
import { jwtDecode } from "jwt-decode";
import { resetOrders } from "../order/order";
import { resetAddress } from "../address/address";
import { resetCart } from "../cart/cart";
import { flushNotification, notifyWithError } from "../error/error";
import { resetOffers } from "../offer/offer";
import { formulateTowerError } from "../../../utils/Helpers/formulateTowerError";
import { resetRewards } from "../rewards/rewards";
import { resetWallet } from "../wallet/wallet";
import { formulateWatchTowerError } from "../../../utils/Helpers/formulateWatchTowerError";

const initialState = {
  otpTokenStatus: "idle",
  loginTokenStatus: "idle",
  logoutStatus: "idle",
  updateLangStatus: "idle",
  user: null,
  checkBetaUserStatus: "idle",
  betaUser: null,
};
export const checkBetaUser = createAsyncThunk(
  "auth/checkBetaUser",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.get(`users/beta/check-is-beta/`);

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateWatchTowerError(response.data));
    } else {
      return response.data;
    }
  }
);
export const loginWithPhone = createAsyncThunk(
  "auth/loginWithPhone",
  async (data, thunkAPI) => {
    const requestBody = {
      app: "Pu9oVTU=",
      grand_type: "phone",
      lang: "ar",
      phone: data,
      phone_country: "SA",
    };
    const response = await towerInstance.post(`login`, requestBody);
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateTowerError(response.data));
    } else {
      return response.data.data;
    }
  }
);
export const loginWithOTP = createAsyncThunk(
  "auth/loginWithOTP",
  async (data, thunkAPI) => {
    const requestBody = {
      app: "Pu9oVTU=",
      grand_type: "otp",
      lang: "ar",
      otp_key: data,
      otp_token: localStorage.getItem("otp_token"),
    };
    const response = await towerInstance.post(`login`, requestBody);
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateTowerError(response.data));
    } else {
      return response.data;
    }
  }
);

export const updateUserLang = createAsyncThunk(
  "auth/updateUserLang",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.put(`users/update-language/`, {
      language: data.lang,
    });
    if (response.status >= 200 || response.status < 300) {
      return response.data;
    }
  }
);

export const logOut = createAsyncThunk(
  "auth/logOut",
  async (data, thunkAPI) => {
    const response = await towerInstance.post(`logout`);
    if (response.data.type === "success") {
      // Clear Smartlook session flag
      localStorage.removeItem("smartlookSessionStarted");
      thunkAPI.dispatch(resetOrders());
      thunkAPI.dispatch(resetAddress());
      thunkAPI.dispatch(resetCart());
      thunkAPI.dispatch(flushNotification());
      thunkAPI.dispatch(resetOffers());
      thunkAPI.dispatch(resetRewards());
      thunkAPI.dispatch(resetWallet());
    }
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateTowerError(response.data));
    } else {
      return response.data;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetauth: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(loginWithPhone.fulfilled, (state, action) => {
      state.loginTokenStatus = "succeeded";
      localStorage.setItem("otp_token", action.payload.otp_token);
    });
    builder.addCase(loginWithPhone.pending, (state, action) => {
      state.loginTokenStatus = "loading";
    });
    builder.addCase(loginWithPhone.rejected, (state, action) => {
      state.loginTokenStatus = "failed";
    });
    builder.addCase(loginWithOTP.fulfilled, (state, action) => {
      state.otpTokenStatus = "succeeded";
      state.user = jwtDecode(action.payload.access_token);
      localStorage.removeItem("otp_token");
      localStorage.setItem("accessToken", action.payload.access_token);
    });
    builder.addCase(loginWithOTP.pending, (state, action) => {
      state.otpTokenStatus = "loading";
    });
    builder.addCase(loginWithOTP.rejected, (state, action) => {
      state.otpTokenStatus = "failed";
    });
    builder.addCase(logOut.fulfilled, (state, action) => {
      state.logoutStatus = "succeeded";
      for (let key in localStorage) {
        if (key !== "lng") {
          localStorage.removeItem(key);
        }
      }
    });
    builder.addCase(logOut.pending, (state, action) => {
      state.logoutStatus = "loading";
    });
    builder.addCase(logOut.rejected, (state, action) => {
      state.logoutStatus = "failed";
    });
    builder.addCase(updateUserLang.fulfilled, (state, action) => {
      state.updateLangStatus = "succeeded";
    });
    builder.addCase(updateUserLang.pending, (state, action) => {
      state.updateLangStatus = "loading";
    });
    builder.addCase(updateUserLang.rejected, (state, action) => {
      state.updateLangStatus = "failed";
    });
    builder.addCase(checkBetaUser.fulfilled, (state, action) => {
      state.betaUser = action.payload.is_beta;
      state.checkBetaUserStatus = "succeeded";
    });
    builder.addCase(checkBetaUser.pending, (state, action) => {
      state.checkBetaUserStatus = "loading";
    });
    builder.addCase(checkBetaUser.rejected, (state, action) => {
      state.checkBetaUserStatus = "failed";
      state.betaUser = false;
    });
  },
});

export const { resetauth } = authSlice.actions;
export default authSlice.reducer;

export const getUserauth = (state) => state.authReducer.userauth;
export const getauthStatus = (state) => state.authReducer.status;
export const getFetchauthStatus = (state) => state.authReducer.fetchauthStatus;
export const getIsNewUserauth = (state) => state.authReducer.isNewUserauth;
